import Vue from 'vue'
import Router from 'vue-router'
import router_list from './router_list'
const requireViews = JSON.parse(router_list.data);
const routes = [{
    path: '/',
    redirect: '/' + requireViews[0], //设置默认指向的路径
    name: '/' + requireViews[0]
}];
//根据路径生成路由列表
requireViews.forEach(item => {
    routes.push({
        path: '/' + item,
        name: item,
        component: () =>
            import (`../pages/${item}/${(item.indexOf('/') != -1 ? item.split('/').pop() : item)}.vue`)
    })
});
Vue.use(Router)
export default new Router({
    routes
})