class UtilsApis {
    constructor(api) {
        this.api = api;
    };
    //用户详情
    memberInfo(data) {
        return this.api.request('/member/info', data, "GET")
    };
    //微信jssdk
    jsConfig(data) {
        return this.api.request('/index/js-config', data, "GET")
    };
    //核销票据
    manageCheck(data) {
        return this.api.request('/manage/check', data, "POST")
    };
    //可核销的第三方渠道
    thirdChannel(data) {
        return this.api.request('/manage/third-channel', data, "GET")
    };
    //查询第三方卡券
    viewThirdCoupon(data) {
        return this.api.request('/manage/view-third-coupon', data, "GET")
    };
    //核销第三方卡券
    checkThirdCoupon(data) {
        return this.api.request('/manage/check-third-coupon', data, "POST")
    };
    //订单id-核销票据
    orderCheck(data) {
        return this.api.request('/manage/order_check', data, "POST")
    };

    //核销记录
    checkList(data) {
        return this.api.request('/manage/check-list', data, "GET")
    };
    //搜索订单(已付款的最近20个订单)
    search(data) {
        return this.api.request('/manage/search', data, "GET")
    };
    //管理员申请退款
    refund(data) {
        return this.api.request('/manage/refund', data, "POST")
    };
    //申请退款
    orderRefund(data) {
        return this.api.request('/order/refund', data, "POST")
    };
    //注册/登录
    login(data) {
        return this.api.request('/member/login', data, "POST")
    };
    //商场列表
    mallList(data) {
        return this.api.request('/index/mall', data, "GET")
    };
    //商场门票
    activeIndex(data) {
        return this.api.request('/active/index', data, "GET")
    };
    //创建订单
    activeCheckout(data) {
        return this.api.request('/active/checkout', data, "POST")
    };
    //支付
    payment(id, data) {
        return this.api.request('/order/payment/' + id, data, "GET")
    };
    //门票详情
    ticketInfo(id, data) {
        return this.api.request('/active/ticket-info/' + id, data, "GET")
    };
    //订单详情
    orderInfo(id, data) {
        return this.api.request('/order/info/' + id, data, "GET")
    };
    //分享订单
    orderShare(id, data) {
        return this.api.request('/order/share/' + id, data, "GET")
    };
    //订单详情
    manageInfo(id, data) {
        return this.api.request('/manage/order-info/' + id, data, "GET")
    };
    //订单列表
    orderIndex(data) {
        return this.api.request('/order/index', data, "GET")
    };
    //出示二维码
    showTicket(id, data) {
        return this.api.request('/order/show-ticket/' + id + '', data, "GET")
    };
    //场地列表
    space_list(data) {
        return this.api.request('/space/space_list', data, "GET")
    };
    //关注场地动作
    favorite_active(id, data) {
        return this.api.request('/favorite/active/' + id, data, "POST")
    };
    //关注列表
    favorite_list(data) {
        return this.api.request('/favorite/list', data, "GET")
    };
    //场地列表-通过id查询
    space_list_ids(data) {
        return this.api.request('/space/space_list_ids', data, "GET")
    };
    //场地详情
    space_info(id, data) {
        return this.api.request('/space/info/' + id, data, "GET")
    };
    //场地相关推荐
    space_relevant(id, data) {
        return this.api.request('/space/relevant/' + id, data, "GET")
    };
    //项目详情
    space_project(id, data) {
        return this.api.request('/space/project/' + id, data, "GET")
    };
    //创建咨询单
    add_order(data) {
        return this.api.request('/brand_order/add', data, "POST")
    };
    //咨询单列表
    brand_order(data) {
        return this.api.request('/brand_order/list', data, "GET")
    };
    //咨询单详情
    brand_order_info(id, data) {
        return this.api.request('/brand_order/info/' + id, data, "GET")
    };
    //全景图
    space_fullview(id, data) {
        return this.api.request('/space/fullview/' + id, data, "GET")
    };
    //通用接口-上传
    common_upload(data) {
        return this.api.request('/common/upload', data, "POST")
    };
    //通用接口-数据字典
    data_dict(data) {
        return this.api.request('/common/data_dict', data, "GET")
    };
    //通用接口-发送短信
    login_sms(data) {
        return this.api.request('/index/send-code', data, "POST")
    };
    //优惠券详情-查询sn
    coupon_info(data, id) {
        return this.api.request('/coupon/info/' + id, data, "GET")
    };
    //领取优惠券-查询sn
    coupon_receive(data) {
        return this.api.request('/coupon/receive/' + data, {}, "POST")
    };
    //一码一用优惠券详情-查询sn
    coupon_info_single(data, id) {
        return this.api.request('/coupon/info_single/' + id, data, "GET")
    };
    //领取一码一用优惠券-查询sn
    coupon_receive_single(data) {
        return this.api.request('/coupon/receive_single/' + data, {}, "POST")
    };
    //我的卡券
    coupon_my(data) {
        return this.api.request('/coupon/my', data, "GET")
    };
    //获取退款原因
    order_refund_reason(data) {
        return this.api.request('/order/refund-reason', data, "GET")
    };
}

export default {
    UtilsApis,
}