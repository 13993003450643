/* eslint-disable no-undef */
import axios from 'axios';
import qs from 'qs';
import host from './host.js';
import Vue from 'vue'
import { resetRouter } from '../router/router';


//获取url token mallid 赋值变量
class configApi {
    request(url, data, method) {
        url = host.http_host + url
        return new Promise((resolve, reject) => {
            try {
                axios({
                    data: method == 'GET' ? qs.stringify(data) : '',
                    url: url,
                    params: data,
                    method: method,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + localStorage.getItem('user_token'),
                        // 'Authorization': 'Bearer I7Vh94cp2Md1P8S64B9QaY1xsA4npayN',
                    }
                }).then(function(res) {
                    res.is_login = true;
                    if (window.location.href.indexOf('share') == -1 && window.location.href.indexOf('mallList') == -1 && window.location.href.indexOf('coupon') == -1 && window.location.href.indexOf('login') == -1 && window.location.href.indexOf('home') == -1 && window.location.href.indexOf('single') == -1 && window.location.href.indexOf('weeks') == -1) {
                        if (!localStorage.getItem('user_token')) {
                            window.location.replace('//paradise.h5.airland100.com/#/login');
                            res.is_login = false;
                        } else {
                            if (!localStorage.getItem('mall_id')) {
                                window.location.replace('//paradise.h5.airland100.com/#/login');
                                res.is_login = false;
                            }
                        }
                        if (res.data.code == 401) {
                            window.location.replace('//paradise.h5.airland100.com/#/login');
                            res.is_login = false;
                        }
                    } else {
                        if (!localStorage.getItem('user_token')) {
                            res.is_login = false;
                        }
                        if (res.data.code == 401) {
                            res.is_login = false;
                        }
                    }
                    resolve(res);
                }).catch(function() {
                    reject()
                })
            } catch (e) {
                axios({
                    data: method == 'GET' ? qs.stringify(data) : '',
                    url: url,
                    params: data,
                    method: method,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + localStorage.getItem('user_token'),
                        // 'Authorization': 'Bearer I7Vh94cp2Md1P8S64B9QaY1xsA4npayN',
                    }
                }).then(function(res) {
                    res.is_login = true;
                    if (window.location.href.indexOf('share') == -1 && window.location.href.indexOf('mallList') == -1 && window.location.href.indexOf('coupon') == -1 && window.location.href.indexOf('login') == -1 && window.location.href.indexOf('home') == -1 && window.location.href.indexOf('single') == -1 && window.location.href.indexOf('weeks') == -1) {
                        if (!localStorage.getItem('user_token')) {
                            window.location.replace('//paradise.h5.airland100.com/#/login');
                            res.is_login = false;
                        } else {
                            if (!localStorage.getItem('mall_id')) {
                                window.location.replace('//paradise.h5.airland100.com/#/login');
                                res.is_login = false;
                            }
                        }
                        if (res.data.code == 401) {
                            window.location.replace('//paradise.h5.airland100.com/#/login');
                            res.is_login = false;
                        }
                    } else {
                        if (!localStorage.getItem('user_token')) {
                            res.is_login = false;
                        }
                        if (res.data.code == 401) {
                            res.is_login = false;
                        }
                    }
                    resolve(res)
                }).catch(function() {
                    reject()
                })
            }


        })


    }
}

export default {
    configApi,
}